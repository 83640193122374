<template>
  <div class="max-w-7xl mx-auto pb-24">
    <TemplateForm
      ref="templateForm"
      :preview="false"
      formType="installation"
      :schema="[]"
      :allFields="[]"
      :props-device="device"
      :newInstallation="true"
      :require-signature="false"
      @submit="addToDatabase"
    />

    <Modal
      ref="modal"
      :title="$t('installation.addingToDatabase')"
      :text="`${this.$t('installation.addingToDatabaseText1')} ${orgName}. ${this.$t('installation.addingToDatabaseText2')}`"
      :cancel-button-label="$t('cancel')"
    >
      <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="addToDatabase">
        {{$t("installation.addToDatabase")}}
      </button>
    </Modal>
  </div>
</template>

<script>
import TemplateForm from "@/components/admin/FormTemplate/TemplateForm.vue";
import Modal from '@/components/layout/Modal.vue';
import { mapState } from 'vuex'
import { deserializeParams } from '@/assets/js/helpers.js'

export default {
  name: "AddDevice",
  components: {
    TemplateForm,
    Modal
  },
  data () {
    return {
      action: null,
    }
  },
  computed: {
     ...mapState(['device', 'user', 'installations', 'currentInstallation', 'organisation']),
     orgName () {
       return process.env.VUE_APP_ORG_NAME
     },
  },
  async created () {
    if (!this.device) {
      this.$router.push('services')
    }
  },
  mounted () {
  },
  methods: {
    async addToDatabase (formData) {
      //this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

      const parameters = this.device.parameters
      delete parameters.validated

      let data = {
        customDevice: deserializeParams({
          deviceTemplate: `/device_templates/${formData.device.deviceTemplate.id}`,
          schemaVersion: `/device_template_schema_versions/${formData.device.deviceTemplate.activeVersion.id}`,
          organisation: `/organisations/${this.organisation}`,
          parameters
        }),
        status: 'no_warranty',
        adminNotes: `${this.$t("installation.withoutApp")}`,
        organisation: `/organisations/${this.organisation}`,
        language: this.$i18n.locale
      }

      this.action = 'insert'
      data = Object.assign({}, formData.customer.customerData, data)

      let request = {
        status: 0,
        endpoint: `/installations`,
        type: 'installation',
        state: 'online',
        incognito: false,
        action: this.action,
        data: data
      }

      this.$store.dispatch('addRequest', request)
      this.$router.push({path: '/'})
    }
  },
  watch: {
    step () {
      this.$router.push({hash: `#step${this.step}`})
    },
  }
};
</script>
