<template>
  <div v-if="device" class="max-w-7xl mx-auto pb-24">
    <TemplateForm
      :initialData="currentInstallation?.survey?.data ? JSON.parse(JSON.stringify(currentInstallation.survey.data)) : null"
      ref="templateForm"
      :preview="false"
      formType="installation"
      :schemaVersionId="schemaVersion.id"
      :schema="schemaVersion.installationFormSchema"
      :allFields="fieldsFlat"
      :props-device="device"
      :newInstallation="$route.params.id === 'new'"
      :incognito="incognito"
      @submit="submit"
    />

    <Modal
      ref="modal"
      :title="$t('template.warning')"
      :text="modalText"
      :cancel-button-label="$t('template.understood')"
    >
    </Modal>
  </div>
</template>

<script>
import TemplateForm from "@/components/admin/FormTemplate/TemplateForm.vue";
import Modal from '@/components/layout/Modal.vue';
import { mapState } from 'vuex'
import { deserializeParams } from '@/assets/js/helpers.js'

export default {
  name: "AddDevice",
  components: {
    TemplateForm,
    Modal
  },
  data () {
    return {
      offline: this.$route.query.offline === 'true',
      incognito: this.$route.query.incognito === 'true',
      position: null,
      action: null,
      modalText: "",
      intitialData: null
    }
  },
  computed: {
     ...mapState(['device', 'user', 'installations', 'currentInstallation', 'organisation']),
     schemaVersion () {
       console.log(this.device)
       if (!this.device) return []
       return this.device.schemaVersion && this.device.schemaVersion.id ? this.device.schemaVersion : this.device.deviceTemplate.activeVersion
     },
     fieldsFlat() {
       console.log(this.schemaVersion.installationFormSchema)
       return this.schemaVersion.installationFormSchema ? this.schemaVersion.installationFormSchema.map((step, stepIndex) => {
         return step.schema.map(field => {return {...field, stepIndex: stepIndex}})
       }).flat(1) : []
     }
  },
  async created () {
    console.log(this.currentInstallation)
    if (!this.device) {
      if (!this.$route.params.deviceId) this.$router.push({path: '/installations'})
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('getDevice', { id: this.$route.params.deviceId }).then((result) => {
        this.$store.dispatch('setLoading', false)
        console.log(result)
        if (result.status === 'not-found') {
          this.$router.push({path: '/installations'})
        } else if (this.device.installation) {
          this.$router.push({path: `/installations/${this.device.installation.id}`})
          this.$notify({
            title: "Błąd",
            text: `Urządzenie u numerze seryjnym ${this.device.id} figuruje w bazie danych jako uruchomione`,
            type: 'error',
            duration: 5000
          })
        }
      }).catch((err) => {
        this.$store.dispatch('setLoading', false)
        console.log(err)
      })
    }
  },
  mounted () {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(this.savePosition, this.positionError);
    else this.position = "Urządzenie nie obsługuje lokalizacji."
  },
  methods: {
    savePosition (position) {
      this.position = position.coords.latitude + "," + position.coords.longitude
    },
    postionError (error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.position = "Użytkownik odrzucił prośbę o udostępnienie lokalizacji."
          break;
        case error.POSITION_UNAVAILABLE:
          this.position = "Likalizacja nie była dostępna."
          break;
        case error.TIMEOUT:
          this.position = "Użytkownik odrzucił prośbę o udostępnienie lokalizacji."
          break;
        case error.UNKNOWN_ERROR:
          this.position = "Nie udało się pobrać danych."
          break;
      }
    },
    async submit (formData) {
      if (formData.status == 'mounted-device') {
        this.modalText = formData.modalText
        this.$nextTick(() => this.$refs.modal.show())
        return
      } else if (formData.status == 'offline') {
        this.offline = true
      }
      console.log(formData)
      //this.$refs.modal.close()

      //this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

      const parameters = this.device.parameters
      delete parameters.validated

      const customDevice = this.device.id ? `/custom_devices/${this.device.id}` : deserializeParams({
        deviceTemplate: `/device_templates/${formData.device.deviceTemplate.id}`,
        schemaVersion: `/device_template_schema_versions/${formData.device.deviceTemplate.activeVersion.id}`,
        organisation: `/organisations/${this.organisation}`,
        parameters
      })

      let data = {
        status: 'pending',
        position: this.position,
        organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`,
        customDevice,
        survey: {data: formData.value, notes: formData.notes},
        language: this.$i18n.locale,
        rejectionReason: null
      }

      if (this.$route.params.id === 'new'){
        this.action = 'insert'
        if (this.user && this.user.asp) data['receivers'] = [`/users/${this.user.asp.id}`]
        data = Object.assign({}, formData.customer.customerData, data)
      } else {
        this.action = 'update'
        data['id'] = parseInt(this.$route.params.id),
        data = Object.assign({}, this.currentInstallation, data)
        delete data['receivers']
      }

      delete data['installer']

      let request = {
        endpoint: this.incognito ? null : '/installations',
        type: 'installation',
        state: this.offline ? 'offline' : 'online',
        incognito: this.incognito,
        action: this.action,
        files: formData.files,
        signature: formData.customer.signatureImage,
        data,
        installerId: this.incognito ? null : this.user.id,
        status: 0
      }

      /*if (this.offline) {
        const pdfData = {
          device: formData.device,
          formData: formData.value,
          files: formData.files,
          notes: formData.notes,
          customer: formData.customer
        }

        request['pdfData'] = JSON.parse(JSON.stringify(pdfData))
      }*/

      console.log(request)

      this.$store.dispatch('addRequest', request)
      this.$router.push({path: '/'})
    }
  },
  watch: {
    position() {
      console.log(this.position)
    }
  }
};
</script>
